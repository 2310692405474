import React from "react";

const ModalError = ({ showErrorModal, coupon_error, onClose }) => {
  if (!showErrorModal) return null;

  let errorMessage = "เกิดข้อผิดพลาด";

  switch (coupon_error) {
    case 1:
      errorMessage = "ไม่พบคูปอง";
      break;
    case 2:
      errorMessage = "จำนวนการใช้งานคูปองครบกำหนดแล้ว";
      break;
    case 3:
      errorMessage = "คูปองนี้ไม่สามารถใช้งานในสาขานี้ได้";
      break;
    case 4:
      errorMessage = "คูปองหมดอายุ";
      break;
    case 5:
      errorMessage = "คูปองถูกใช้งานไปแล้ว";
      break;
    case 6:
        errorMessage = "ไม่มีเบอร์นี้ในระบบ";
      break;
    default:
      errorMessage = "ไม่พบคูปอง";
  }

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <h2 style={styles.header}>เกิดข้อผิดพลาด</h2>
        <p style={styles.message}>{errorMessage}</p>
        <button style={styles.button} onClick={onClose}>
          ปิด
        </button>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  modal: {
    background: "#fff",
    padding: "20px",
    borderRadius: "10px",
    textAlign: "center",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
    minWidth: "300px",
  },
  header: {
    margin: "0 0 10px",
    fontSize: "20px",
    color: "#d9534f",
  },
  message: {
    marginBottom: "20px",
    fontSize: "16px",
    color: "#333",
  },
  button: {
    padding: "10px 20px",
    background: "#d9534f",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
  },
};

export default ModalError;
